<template>
  <LayerModalForm data-cy="modal-form" class="layer-modal-form-confirm">
    <template #fields>
      <slot />
    </template>

    <template #formFooter>
      <MButton
        v-if="!cancelBtnIsHidden"
        data-cy="cancel-button"
        type="button"
        :theme="cancelBtnTheme"
        :label="cancelBtnLabel"
        @click="$emit('close')"
      />
      <MButton
        v-if="!confirmBtnIsHidden"
        data-cy="submit-button"
        type="submit"
        :theme="confirmBtnTheme"
        :label="confirmBtnLabel"
        :disabled="disabledConfirmBtn"
        @click="$emit('submit')"
      />
      <ViewHelpLink
        v-if="documentationAnchor"
        :documentationAnchor="documentationAnchor"
      />
    </template>
  </LayerModalForm>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import ViewHelpLink from "@/commons/components/UserDocumentationLinks/ViewHelpLink.vue";
import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";

import contents from "@/commons/contents/modal";

export default {
  components: {
    ViewHelpLink,
    LayerModalForm,
    MButton,
  },
  props: {
    cancelBtnLabel: {
      type: String,
      default: contents.cancelBtn,
    },
    cancelBtnTheme: {
      type: String,
      default: "bordered-neutral",
    },
    confirmBtnLabel: {
      type: String,
      default: contents.confirmBtn,
    },
    confirmBtnTheme: {
      type: String,
      default: "solid-danger",
    },
    disabledConfirmBtn: {
      type: Boolean,
      default: false,
    },
    confirmBtnIsHidden: {
      type: Boolean,
      default: false,
    },
    cancelBtnIsHidden: {
      type: Boolean,
      default: false,
    },
    documentationAnchor: {
      type: String,
      default: undefined,
    },
  },
  emits: ["close", "submit"],
};
</script>
