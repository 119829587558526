import GroupsSettings from "@/user/views/GroupsSettings.vue";
import UserProfile from "@/user/views/UserProfile.vue";

import { Dictionary } from "@/commons/domain/models/dictionary";

export interface GroupsSettingsUrlQuery {
  term: string;
  page: number;
}

const routes = [
  {
    path: "groups-settings",
    name: "groupsSettings",
    component: GroupsSettings,
    meta: {
      title: "My Groups",
      secured: true,
      realm: "groups-settings",
      crossEnv: true,
    },
    props: (route) => {
      const query = route.query as Dictionary<string>;
      return {
        urlQuery: {
          term: query.term || "",
          page: query.page !== undefined ? Number(query.page) : 1,
        },
      };
    },
  },
  {
    path: "profile",
    name: "userProfile",
    component: UserProfile,
    meta: {
      title: "My Profile",
      secured: true,
      realm: "user-profile",
      crossEnv: true,
    },
  },
];

export default routes;
