/* eslint-disable no-useless-escape */
import {
  IsIn,
  IsNotEmpty,
  Matches,
  MaxLength,
  MinLength,
  ValidateIf,
} from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class UpdateAppForm extends FormData {
  @Matches(/^[\w\s-.']*$/, {
    message: "Only non accented latin character and number are allowed",
  })
  @MinLength(4, {
    message:
      "This field is too short. Minimal length is $constraint1 characters",
  })
  @MaxLength(50, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  name: string;

  @MinLength(30, {
    message:
      "This field is too short. Minimal length is $constraint1 characters",
  })
  @MaxLength(200, {
    message:
      "This field is too long. Maximal length is $constraint1 characters",
  })
  description: string;

  @ValidateIf((form) => form.forTesting === false && form.userIsInternal)
  @IsNotEmpty()
  product: any;

  @IsIn(["IOS", "ANDROID", "WEB", "APPLICATION_SERVER"], {
    message: "This field type can't no be empty",
  })
  type: string;

  forTesting: boolean;

  userIsInternal: boolean;

  @ValidateIf((form) => form.timeZonesFeatureIsEnabled)
  @IsNotEmpty({
    message: "The timezone is required",
  })
  timeZone: string;

  timeZonesFeatureIsEnabled: boolean;

  $clear() {
    for (const key of ["name", "productId", "description", "timeZone"]) {
      this[key] = "";
    }
    this.forTesting = false;
    this.userIsInternal = true;
    this.timeZonesFeatureIsEnabled = false;
  }

  static create() {
    return FormFactory.createForm(new UpdateAppForm());
  }
}
