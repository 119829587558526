<script setup lang="ts">
import MFlag from "@mozaic-ds/vue-3/src/components/flag/MFlag.vue";
import { computed, PropType } from "vue";
import { useStore } from "vuex";

import { Token } from "@/commons/domain/models/token";

import {
  ETokenStatus,
  ETokenSynchronizationStatus,
} from "@/commons/store/types";

import contents from "@/dashboard/contents/token-secret-synchronization-display";

const props = defineProps({
  token: {
    type: Object as PropType<Token>,
    required: true,
  },
});

const store = useStore();

const currentApplicationSecretSynchronizationEnabled = computed(
  () => store.getters["currentApplicationSecretSynchronizationEnabled"],
);

const secretManager = computed(
  () => store.getters["config/secretSynchronizationManager"],
);

const getTokenSyncLabel = (status: ETokenSynchronizationStatus) => {
  if (status === ETokenSynchronizationStatus.SYNCHRONIZED) {
    return contents.synchronizedTokenLabel(secretManager.value);
  } else if (status === ETokenSynchronizationStatus.PENDING) {
    return contents.pendingSyncTokenLabel(secretManager.value);
  } else {
    return undefined;
  }
};

function getTokenSynchronizationStatusTheme(tokenId: string): string {
  const tokenStatus = getTokenSynchronizationStatus(tokenId);

  return tokenStatus === ETokenSynchronizationStatus.SYNCHRONIZED
    ? "primary"
    : getPendingTokenSynchronizationStatus();
}

const getPendingTokenSynchronizationStatus = () => {
  return secretSynchronizationIsEnabled.value ? "bordered" : "danger";
};

const secretSynchronizationIsEnabled = computed(
  () => store.getters["config/secretSynchronizationIsEnabled"],
);

const currentApplicationTokensSynchronizationStatuses = computed(() => {
  return store.getters[
    "currentApplicationTokensSynchronizationStatuses"
  ] as Map<string, ETokenSynchronizationStatus>;
});

const isDisabled = computed(
  () => props.token?.status === ETokenStatus.DISABLED,
);

function tokenHasSynchronizationStatus(tokenId: string): boolean {
  return (
    currentApplicationTokensSynchronizationStatuses.value != undefined &&
    currentApplicationTokensSynchronizationStatuses.value[tokenId] != undefined
  );
}

function getTokenSynchronizationStatus(
  tokenId: string,
): ETokenSynchronizationStatus {
  return currentApplicationTokensSynchronizationStatuses.value[tokenId];
}
</script>

<template>
  <MFlag
    v-if="
      !isDisabled &&
      currentApplicationSecretSynchronizationEnabled &&
      tokenHasSynchronizationStatus(token.id)
    "
    class="token-secret-synchronization-display"
    :label="getTokenSyncLabel(getTokenSynchronizationStatus(token.id))"
    :theme="getTokenSynchronizationStatusTheme(token.id)"
  />
</template>
