import store from "@/commons/store";

import Home from "./views/Home/Home.vue";
import UseCase from "./views/UseCase/UseCase.vue";
import UseCases from "./views/UseCases.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Devportal",
      crossEnv: true,
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["userIsLogged"]) {
        next({ name: "dashboard" });
      }
      next();
    },
  },
  {
    path: "usecases",
    name: "useCases",
    component: UseCases,
    meta: {
      title: "Devportal",
      crossEnv: true,
    },
  },
  {
    path: "usecases/:slug",
    name: "useCase",
    component: UseCase,
    meta: { title: "Devportal", crossEnv: true },
  },
];

export default routes;
