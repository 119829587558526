<script setup lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { computed, PropType } from "vue";
import { useStore } from "vuex";

import IconTooltip from "@/commons/components/IconTooltip.vue";

import { labelizeTimezone } from "@/commons/libs/utils/stringUtils";
import { localeDateFormats, toDateFormat } from "@/commons/utils/date-utils";
import {
  ExpirationDateDetails,
  getExpirationDateDetails,
  getSuggestedUserTimezone,
  getTimezoneDate,
  getTimezoneHour,
} from "@/commons/utils/timezones.utils";

import { Token } from "@/commons/domain/models/token";

import { ETokenStatus } from "@/commons/store/types";

import contents from "@/dashboard/contents/token-expiration-date-display";

const props = defineProps({
  token: {
    type: Object as PropType<Token>,
    required: true,
  },
  timeZone: {
    type: String,
    required: true,
  },
  hasManagerRights: {
    type: Boolean,
    default: false,
  },
  textOnly: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const timeZonesFeatureIsEnabled = computed((): boolean => {
  return store.getters["config/timeZonesFeatureIsEnabled"];
});

const expirationDate = computed((): string => {
  return props.token?.expireAt;
});

const formattedExpirationDate = computed((): string => {
  return toDateFormat(
    getTimezoneDate(expirationDate.value, getSuggestedUserTimezone()),
    localeDateFormats.TOKEN_VERBOSE_DATE,
  );
});

const formattedExpirationTime = computed((): string => {
  return getTimezoneHour(expirationDate.value, getSuggestedUserTimezone());
});

const expirationDetails = computed((): ExpirationDateDetails => {
  return getExpirationDateDetails(expirationDate.value);
});

const label = computed((): string => {
  if (isExpired.value) {
    return getExpiredLabel();
  } else if (isAboutToExpire.value) {
    return getAboutToExpireLabel();
  }
  return contents.expiresOnLabel(formattedExpirationDate.value);
});

const getExpiredLabel = () => {
  if (expirationDetails.value?.isToday) {
    return contents.expiredTodayLabel(formattedExpirationTime.value);
  } else if (expirationDetails.value?.isYesterday) {
    return contents.expiredYesterdayLabel(formattedExpirationTime.value);
  } else if (expirationDetails.value?.diffInDays <= 5) {
    return contents.expiredDaysAgoLabel(expirationDetails.value?.diffInDays);
  }
  return contents.expiredSinceLabel(formattedExpirationDate.value);
};

const getAboutToExpireLabel = () => {
  if (expirationDetails.value?.isToday) {
    return contents.expiresTodayAtLabel(formattedExpirationTime.value);
  } else if (expirationDetails.value?.isTomorrow) {
    return contents.expiresTomorrowAtLabel(formattedExpirationTime.value);
  }
  return contents.expiresInLabel(expirationDetails.value?.diffInDays);
};

const isDisabled = computed(
  () => props.token?.status === ETokenStatus.DISABLED,
);

const isExpired = computed(() => props.token?.status === ETokenStatus.EXPIRED);

const isAboutToExpire = computed(() => props.token?.isAboutToExpire);

const type = computed((): string => {
  if (isDisabled.value) {
    return "neutral";
  }
  if (isExpired.value) {
    return "danger";
  }
  if (isAboutToExpire.value) {
    return "warning";
  }
  return "success";
});

const tooltipText = computed((): string => {
  const date = toDateFormat(
    getTimezoneDate(expirationDate.value, props.timeZone),
    localeDateFormats.LONG_VERBOSE_DATE,
  );

  const time = getTimezoneHour(expirationDate.value, props.timeZone);

  const timezone = labelizeTimezone(props.timeZone)
    .replace("(", "")
    .replace(")", "");

  const displayCanChangeTimezoneText =
    timeZonesFeatureIsEnabled.value && props.hasManagerRights;

  return `${contents.expirationTooltipText(isExpired.value, date, time, timezone)} ${displayCanChangeTimezoneText ? contents.updateTimezoneText : ""}`;
});
</script>

<template>
  <div class="expiration-date-display">
    <span v-if="textOnly"> {{ label }}</span>
    <MBadge
      v-else
      class="expiration-date-display__badge"
      data-cy="token-expiration-date"
      :type="type"
    >
      {{ label }}
    </MBadge>
    <IconTooltip :text="tooltipText" />
  </div>
</template>

<style lang="scss">
.expiration-date-display {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.expiration-date-display__badge {
  text-transform: none;
}
</style>
