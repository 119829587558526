<template>
  <div class="contract-loading-skeleton">
    <div>
      <div class="header-actions header-actions__left"></div>
      <div class="header-actions header-actions__right"></div>
    </div>
    <div class="contract-loading-skeleton__wrapper">
      <div class="contract-loading-skeleton__body">
        <div class="loading-h4"></div>
      </div>
      <div class="contract-loading-skeleton__footer">
        <div class="key-tabs">
          <div class="tab-buttons">
            <a href="javascript:" class="tab-btn active">
              <div class="loading-tab-title-1"></div>
              <div class="loading-tab-title-2"></div>
            </a>
          </div>
          <div class="tab-contents">
            <div class="key-tab">
              <div class="loading-token__link"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "ContractLoadingSkeleton",
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.contract-loading-skeleton {
  line-height: 1.3;

  .contract-loading-skeleton__wrapper {
    min-height: 20rem;
  }

  .title {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &__type {
      font-size: 1rem;
    }
  }
  &.pending {
    opacity: 0.5;
    .contract-loading-skeleton__wrapper {
      pointer-events: none;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        content: "";
        background-color: rgb(var(--color-light-grey-rgba) 0.3);
      }
    }

    .tab-buttons {
      border-color: var(--border-default) !important;
    }

    .contract-pending {
      position: absolute;
      top: 4rem;
      font-size: 2rem;
      color: var(--color-grey);
    }
  }

  &__before-header {
    display: flex;
    .header-actions {
      display: flex;
      align-items: center;
      width: 50%;
      &__left {
        justify-content: flex-start;
      }

      &__right {
        justify-content: flex-end;
      }
    }
    .link-icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 1.4rem;
      height: 1.4rem;
      margin: 0.3rem;
      font-size: 1.2rem;
      color: var(--color-grey);
      text-decoration: none;

      &__quota {
        width: auto;

        span {
          font-size: 0.9rem;
        }
      }
    }
  }

  &__wrapper {
    position: relative;
    border: var(--border-default);
    border-radius: 5px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    text-align: center;
  }

  /* stylelint-disable scss/at-extend-no-missing-placeholder */
  &.no-contract {
    @extend .contract-loading-skeleton__body;
    font-size: 0.9rem;
    color: var(--color-light-grey);
    pointer-events: none;
    &::before {
      display: block;
      width: 3rem;
      height: 3rem;
      margin-bottom: 1.5rem;
      content: "";
      background: transparent url("~@/commons/assets/icons/icon-api-empty.svg")
        no-repeat center center;
    }
  }
  /* stylelint-enable scss/at-extend-no-missing-placeholder */

  .key-tabs {
    .tab-buttons {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 40px;
      border-bottom: solid 1px var(--color-primary);

      .tab-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 3rem;
        text-decoration: none;

        &.active {
          border-bottom: solid 3px var(--color-primary);
        }
      }
    }

    .tab-contents {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 3rem;
      padding: 0.5rem 1rem;
    }
  }

  .key-tab {
    .cp {
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;
    }

    a {
      text-decoration: none;
    }
  }
}

.tab-contents {
  overflow: hidden;
  .key-tab {
    width: 100%;
    overflow: hidden;
  }
}

.loading-token__link {
  width: 220px;
  height: 0.7rem;
  margin: 0.7rem;
  background-color: var(--color-light-grey);
  border-radius: 2rem;
}

.loading-tab-title-1,
.loading-tab-title-2 {
  width: 80px;
  height: 0.7rem;
  margin: 0.2rem;
  background-color: var(--color-light-grey);
  border-radius: 2rem;
}

.loading-tab-title-2 {
  width: 60px;
}

.loading-h4 {
  width: 100px;
  height: 0.9rem;
  margin: 0.76rem 0;
  background-color: var(--color-light-grey);
  border-radius: 2rem;
}
</style>
