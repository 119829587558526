export default {
  accessSettings: "Access settings",
  applicationSettings: "Application settings",
  delete: "Delete",
  deleteApplication: "Delete application",

  noApiReadWriteGuideline: "To get started add an API from the left menu",
  noApiReadOnlyGuideline:
    "Sorry! You have insufficient privileges on this application to establish a subscription. Please contact an application manager if you want to go further.",
  noApiTitle: "Your application doesn't have any APIs available to use yet",
  notGoBack: "You will not be able to go back",
  sensitiveModifications: "Sensitive modifications",
  type: "Type",
  showSubscribableApisButton: "Subscribe to a new API",

  apiKeyTabLabel: (contactsNumber) => `API Key (${contactsNumber})`,
  oauthCCFTabLabel: (contactsNumber) =>
    `OAuth - Client Credentials Flow (${contactsNumber})`,
  oauthACFTabLabel: (contactsNumber) =>
    `OAuth - Authorization Code Flow (${contactsNumber})`,

  noApiFoundForSelectedFilters: "No API found for selected filters",
  searchSubscribedApi: "Search your subscribed APIs by name",

  newClientIdToastTitle: "New OAuth subscription",
  newClientIdToastContent:
    "A new ClientID has been created. Please use it only in an API context, to avoid any side impact when it will " +
    "be deleted from DevPortal.",

  updateAppViewHelpDocAnchor: "#update-your-applications-settings",
};
