import { getGroupWithUsersAclsRepository } from "@/commons/repositories/libs/get-group-with-users-acls-repository";

import { GroupFetchParams } from "@/commons/domain/repositories/group-with-users-acls-repository";

export async function searchGroupsInApplicationSettings(
  query: string,
  productId?: string,
) {
  const params = {
    name: query,
    productId,
    showAll: true,
    sortMCCFirst: true,
  };

  return (
    await getGroupWithUsersAclsRepository().cancelAndFind(params, "searchGroup")
  ).data;
}

export async function searchGroupsInApiSettings(
  query: string,
  productId: string,
) {
  const params = {
    name: query,
    showAll: true,
    internalOnly: true,
    productId,
    sortMCCFirst: true,
  };

  return (
    await getGroupWithUsersAclsRepository().cancelAndFind(params, "searchGroup")
  ).data;
}

export async function searchGroupInApiAccessRestrictions(
  query: string,
  withPartners: boolean,
) {
  const params = {
    name: query,
    showAll: true,
    withPartner: withPartners,
    internalOnly: !withPartners,
    sortMCCFirst: true,
  };

  return (
    await getGroupWithUsersAclsRepository().cancelAndFind(params, "searchGroup")
  ).data;
}

/**
 * For Api Creation internalOnly = true
 * because groups with partners can not be manager group of an API
 **/
export async function searchUserGroupsApiCreation(
  query: string,
  productId: string,
) {
  return await searchUserGroups(query, true, productId);
}

export async function searchUserGroupsApplicationCreation(
  query: string,
  productId: string,
) {
  return await searchUserGroups(query, false, productId);
}

export async function searchUserGroups(
  query: string,
  internalOnly: boolean,
  productId?: string,
) {
  const params: GroupFetchParams = {
    name: query,
    size: 100, // give the user a chance to see all the groups
    productId,
    sortMCCFirst: true,
    internalOnly,
  };

  return (
    await getGroupWithUsersAclsRepository().cancelAndFind(
      params,
      "searchUserGroups",
    )
  ).data;
}
