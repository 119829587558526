<template>
  <Layout2 class="application">
    <LayoutSection
      v-if="application"
      class="application__header"
      background="primary"
      noVerticalPadding
      fullWidthContent
    >
      <ApplicationHeader :application="application" />
    </LayoutSection>

    <LayoutSection background="primary" noVerticalPadding>
      <div class="application__menu">
        <div class="application__btn-actions gap-1200">
          <div
            v-for="btn in btnActions"
            :key="btn.route"
            :class="[
              'action',
              btn.route === activeRoute && 'btn-action-active',
            ]"
            :data-cy="`application-menu-${btn.label.toLowerCase()}`"
          >
            <router-link
              class="action-title"
              :to="{ name: btn.route, params: { id } }"
            >
              <h6>
                {{ btn.label }}
                {{
                  btn.route === "application" ? `(${totalContractsCount})` : ""
                }}
              </h6>
            </router-link>
          </div>
        </div>
      </div>
    </LayoutSection>

    <LayoutSection class="application__content" background="secondary">
      <router-view v-if="application && application.id === applicationId" />
    </LayoutSection>
  </Layout2>
</template>

<script lang="ts">
import Layout2 from "@/commons/components/Layout2.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import ApplicationHeader from "@/dashboard/components/ApplicationHeader.vue";

import { updatePageTitle } from "@/commons/libs/router-update-page-meta";
import { date } from "@/commons/libs/utils/dateUtils";

import contents from "@/dashboard/contents/application";
import appContents from "@/dashboard/contents/application.json";
import summaryContents from "@/dashboard/contents/summary.json";

export default {
  name: "Application",
  components: {
    ApplicationHeader,
    Layout2,
    LayoutSection,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      applicationTypes: appContents.createApp.ui.applicationTypes,
      sumUi: summaryContents.ui,
      contents,
      readOnlyBtnActions: [
        {
          label: "Subscriptions",
          route: "application",
        },
      ],
      readWriteBtnActions: [
        {
          label: "Settings",
          route: "applicationSettings",
        },
      ],
      externalOnlyBtnActions: [
        {
          label: "Reporting",
          route: "applicationReporting",
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoadingProperty"]("currentApplication");
    },
    application() {
      return this.$store.getters["currentApplication"];
    },
    applicationId() {
      return this.$route.params.id;
    },
    totalContractsCount(): number {
      return this.application?.metrics?.contracts?.total || 0;
    },
    btnActions() {
      let btnActions = this.readOnlyBtnActions;

      if (!this.userIsInternal) {
        btnActions = btnActions.concat(this.externalOnlyBtnActions);
      }

      if (
        this.application &&
        this.application.currentUserAction === "READ_WRITE"
      ) {
        btnActions = btnActions.concat(this.readWriteBtnActions);
      }

      return btnActions;
    },
    activeRoute() {
      return this.$route.name;
    },
    userIsInternal() {
      return this.$store.getters["userIsInternal"];
    },
  },
  async created() {
    await this.$store.dispatch("loadCurrentApplication", {
      appId: this.id,
    });
    updatePageTitle(this.application.name);
  },
  methods: {
    date,
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";
.application__header {
  border-bottom: var(--border-default);
}

.application__menu {
  background-color: var(--color-white);
}

.application__btn-actions {
  display: flex;
  width: var(--layout-content-width);
  margin: 0;

  .action {
    position: relative;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-top: 3px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;

    .action-title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      h6 {
        margin: 0.5rem 0;
        font-weight: initial;
      }
    }
  }
  .btn-action-active {
    border-bottom: 3px solid var(--color-primary);
  }
}

.application__content {
  flex: 1;
}
</style>
