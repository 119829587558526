export default {
  modalTitle: "Create a new application",
  appWithSameNameWarningTitle: "Application already exists",
  appWithSameNameWarningMessage: (appName) =>
    `You already own an application named ${appName}`,
  goToApplicationLinkTitle: "Go to application",
  applicationDescLabel: "Description",
  applicationForTestingTooltip:
    "You will not be able to set a related product for a test application.",
  applicationName: "Application name",
  applicationProductPlaceholder: "Search a related product",
  applicationProductTooltip:
    "If you cannot find your product, make sure it exists in Tangram.",
  applicationType: "Application type",
  applicationTypeLabel: "Select an application type",
  applicationTypes: {
    IOS: "iOS Application",
    ANDROID: "Android Application",
    WEB: "Web Application",
    APPLICATION_SERVER: "Application server",
  },
  relatedProduct: "Related product",
  testApplication: "This is a test application",
  createErrorMessage: "Unable to create the application",
  createButtonLabel: "Create application",
  updateButtonLabel: "Apply change",
  addManagerGroupPlaceholder: "Add a manager group",
  managerGroup: "Manager group",
  managerGroupTooltip:
    "Choose the group responsible for the application. You can find the list of groups where you are a manager.",
  description: "Description",
  linkToGroupsSettings: "Click here to manage groups",
  cancel: "Cancel",
  unableToUpdateAppForTesting:
    "You cannot switch this Application to a test one while the Vault Publication is activated",
  timezoneLabel: "Timezone",
  timezoneSubLabel:
    "To define your business hours (for API keys expiration dates and notifications)",
  timezonePlaceholder: "Search timezone",
  viewHelpDocumentationAnchor: "#create-an-application",
  disabledNameTooltip:
    "You cannot change the name of the application while the secrets synchronization is activated",
  updateTimezoneModalHeader: "Confirm changes",
  updateTimezoneModalTitle: "Expiration date & time will be updated",
  updateTimezoneModalContent:
    "Modifying the timezone will update existing API keys expiration date and time, so that it will always be within local business hours.\n\nDepending on the expiration dates, be aware you may receive new notifications.",
  updateTimezoneConfirmBtnLabel: "Confirm changes",
};
