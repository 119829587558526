export default {
  suggestedTimezoneCategory: "Suggested timezone (browser)",
  commonTimezonesCategory: "Most common timezones",
  allTimeZones: "All timezones",
  noTimezoneFound: "No Time Zone Found",
  commonTimezones: [
    "Europe/Athens",
    "Europe/Paris",
    "Europe/Madrid",
    "Europe/Bucharest",
    "Europe/Kyiv",
    "Africa/Johannesburg",
    "Europe/Lisbon",
    "Europe/Rome",
    "Europe/Warsaw",
    "America/Sao_Paulo",
  ],
};
