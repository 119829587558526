export default {
  expiresOnLabel: (date: string) => `Expires on ${date}`,
  expiresInLabel: (days: number) =>
    `Expires in ${days} day${days > 1 ? "s" : ""}`,
  expiresTomorrowAtLabel: (time: string) => `Expires tomorrow at ${time}`,
  expiresTodayAtLabel: (time: string) => `Expires today at ${time}`,
  expiredSinceLabel: (date: string) => `Expired since ${date}`,
  expiredTodayLabel: (time: string) => `Expired today at ${time}`,
  expiredYesterdayLabel: (time: string) => `Expired yesterday at ${time}`,
  expiredDaysAgoLabel: (days: number) =>
    `Expired ${days} day${days > 1 ? "s" : ""} ago`,
  updateTimezoneText:
    "The timezone for this application can be modified in Settings page.",
  expirationTooltipText: (
    isExpired: boolean,
    date: string,
    time: string,
    timezone: string,
  ) =>
    `${isExpired ? "Expired since" : "Expires on"} ${date} at ${time} (${timezone}).`,
};
