import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "app-list-item" }
const _hoisted_2 = { class: "app-list-item__header" }
const _hoisted_3 = { class: "app-list-item__app-name" }
const _hoisted_4 = {
  key: 0,
  class: "app-list-item__product"
}
const _hoisted_5 = {
  key: 1,
  class: "app-list-item__for-testing"
}
const _hoisted_6 = { class: "app-list-item__description" }
const _hoisted_7 = { class: "app-list-item__footer" }
const _hoisted_8 = {
  key: 0,
  class: "app-list-item__api-keys-expired"
}
const _hoisted_9 = { class: "app-list-item__api-keys-expired-icon" }
const _hoisted_10 = { class: "app-list-item__api-types" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.application.name), 1),
      ($props.application.product)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($data.contents.from) + " \"" + _toDisplayString($props.application.product.name) + "\" ", 1))
        : _createCommentVNode("", true),
      ($props.application.forTesting)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($data.contents.testApplication), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, _toDisplayString($props.application.description
            ? $options.shortDescription($props.application.description)
            : $data.contents.descriptionPlaceholder), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      ($options.metrics.apiKeysExpired > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_MIcon, {
                name: "NotificationWarning32",
                color: "var(--color-danger)"
              })
            ]),
            _createTextVNode(" " + _toDisplayString($options.metrics.apiKeysExpired) + " " + _toDisplayString($data.contents.apiKeys), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("img", { src: $data.apiIcon }, null, 8, _hoisted_11),
        ($options.metrics.contracts.restAPI > 0 || $options.metrics.contracts.topics > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              ($options.metrics.contracts.restAPI > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($options.metrics.contracts.restAPI) + " " + _toDisplayString($data.contents.apiRestContracts), 1))
                : _createCommentVNode("", true),
              ($options.metrics.contracts.restAPI > 0 && $options.metrics.contracts.topics > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, " - "))
                : _createCommentVNode("", true),
              ($options.metrics.contracts.topics > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString($options.metrics.contracts.topics) + " " + _toDisplayString($data.contents.topicContracts), 1))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString($data.contents.noContract), 1)
            ], 64))
      ])
    ])
  ]))
}