<script setup lang="ts">
import { PropType } from "vue";

import DvpToggle from "@/commons/components/DvpToggle.vue";
import TokenExpirationDateDisplay from "@/dashboard/views/ContractsList/contractCard/tokenDisplay/TokenExpirationDateDisplay.vue";

import { Contract } from "@/commons/domain/models/contract";

import { ETokenStatus } from "@/commons/store/types";

defineProps({
  contract: {
    type: Object as PropType<Contract>,
    required: true,
  },
  canUpdate: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["toggleTokenStatus"]);
</script>

<template>
  <ul class="consumers-api-key-credentials">
    <li
      v-for="token in contract.tokens"
      :key="token.id"
      class="consumers-api-key-credentials__item"
    >
      <DvpToggle
        :checked="token.status === ETokenStatus.ENABLED"
        :disabled="!canUpdate"
        :data-cy="`api-key-status-${token.id}`"
        @click="emit('toggleTokenStatus', contract, token)"
      />
      <div class="consumers-api-key-credentials__item-key-and-expiration">
        <span class="color-grey" :data-cy="`api-key-${token.id}`">
          {{ token.key }}
        </span>
        <TokenExpirationDateDisplay
          :token="token"
          :timeZone="contract?.application?.timeZone"
          textOnly
        />
      </div>
    </li>
  </ul>
</template>

<style lang="scss">
.consumers-api-key-credentials {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0; // because ul element adds a big left padding
}

.consumers-api-key-credentials__item {
  display: flex;
  align-items: center;
}

.consumers-api-key-credentials__item-key-and-expiration {
  display: flex;
  flex-direction: column;

  gap: 0.25rem;
}
</style>
