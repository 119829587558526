<script setup lang="ts">
import MAutocomplete from "@mozaic-ds/vue-3/src/components/autocomplete/MAutocomplete.vue";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";

import {
  addTimezoneCategories,
  mapTimezoneItems,
  TimezoneItem,
  getSuggestedUserTimezone,
  mapToUTCTimezones,
} from "@/commons/utils/timezones.utils";

import contents from "@/commons/contents/search-timezone-autocomplete-wrapper";

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(["update:modelValue"]);

const store = useStore();

const localValue = ref("");
const search = ref("");

onMounted(() => {
  updateInputValues(props.modelValue);
});

watch(
  () => props.modelValue,
  () => {
    updateInputValues(props.modelValue);
  },
);

const updateInputValues = (newValue: string) => {
  if (newValue != undefined && localValue.value !== newValue) {
    const result = allTimezoneItems.value.find((item) =>
      item.value.includes(newValue),
    );
    if (result != undefined) {
      localValue.value = result.value;
      search.value = result.label;
    } else {
      localValue.value = "";
      search.value = "";
    }
  }
};

const onUpdateModelValue = (updatedValue: string) => {
  localValue.value = updatedValue;

  const formattedValue =
    updatedValue != undefined
      ? updatedValue
          .replace("common-", "")
          .replace("suggested-", "")
          .replace("available-", "")
      : "";

  emit("update:modelValue", formattedValue);
};

const onInputUpdate = (value: string) => {
  search.value = value;
};

const configTimezones = computed((): string[] => {
  return store.getters["config/timeZones"];
});

const suggestedTimezone = computed(() => {
  const userTimezone = getSuggestedUserTimezone();

  if (userTimezone != undefined) {
    return configTimezones.value.find((timezone: string) =>
      timezone.includes(userTimezone),
    );
  }
  return undefined;
});

const commonTimezones = computed(() => {
  return mapToUTCTimezones(
    contents.commonTimezones.sort(),
    configTimezones.value,
  );
});

const allTimezoneItems = computed((): TimezoneItem[] => {
  return [
    ...mapTimezoneItems(rightNow.value, [suggestedTimezone.value], {
      isSuggestedZone: true,
    }),
    ...mapTimezoneItems(rightNow.value, commonTimezones.value, {
      isCommonZone: true,
    }),
    ...mapTimezoneItems(rightNow.value, configTimezones.value, {
      isAvailableZone: true,
    }),
  ];
});

const timezoneItems = computed((): TimezoneItem[] => {
  const filteredList = allTimezoneItems.value?.filter((item) => {
    return search.value
      ? item.label.toLowerCase().includes(search.value?.toLowerCase())
      : true;
  });

  addTimezoneCategories(filteredList);

  return filteredList;
});

// Handle time update

const rightNow = ref(new Date());
let interval = undefined;

const setTimeInterval = () => {
  interval = setInterval(() => {
    const newDate = new Date();
    if (newDate.getMinutes() !== rightNow.value.getMinutes()) {
      rightNow.value = newDate;
    }
  }, 1000);
};

const clearTimeInterval = () => {
  if (interval != undefined) {
    clearInterval(interval);
  }
};

onUnmounted(() => {
  clearTimeInterval();
});
</script>

<template>
  <MAutocomplete
    class="search-timezone-autocomplete-wrapper"
    v-bind="$attrs"
    :items="timezoneItems"
    :filter="false"
    :empty="timezoneItems?.length === 0"
    :modelValue="localValue"
    @update:input="onInputUpdate"
    @update:modelValue="onUpdateModelValue"
    @open="setTimeInterval"
    @close="clearTimeInterval"
  >
    <template #item="{ item }">
      <div
        :class="[
          {
            'search-timezone-autocomplete-wrapper__list-category-divider':
              item.isCategory,
          },
        ]"
        class="search-timezone-autocomplete-wrapper__item-container"
      >
        <span
          v-if="item.label != undefined"
          :class="{
            'search-timezone-autocomplete-wrapper__list-category':
              item.isCategory,
          }"
        >
          {{ item.label }}</span
        >
        <span
          v-if="!item.isCategory"
          class="search-timezone-autocomplete-wrapper__item-hour"
          :class="[
            {
              'search-timezone-autocomplete-wrapper__item-hour--selected':
                localValue === item.value,
            },
          ]"
          >{{ item.hour }}</span
        >
      </div>
    </template>
  </MAutocomplete>
</template>

<style lang="scss">
.search-timezone-autocomplete-wrapper__item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.search-timezone-autocomplete-wrapper__item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-timezone-autocomplete-wrapper__list-category {
  @include set-text-s;
  color: var(--color-text-minor);
}

.search-timezone-autocomplete-wrapper__list-category-divider {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--color-stroke-light);
}

.search-timezone-autocomplete-wrapper__item-hour {
  padding: 0.125rem 0.25rem;

  text-align: center;

  background-color: var(--color-background-secondary-light);
  border-radius: var(--m-border-radius-small);
}

.search-timezone-autocomplete-wrapper__item-hour--selected {
  margin-right: 2rem;
}
</style>
