<template>
  <div class="application-settings">
    <div class="application-settings__col">
      <CardContainer class="application-settings__card-container">
        <div class="application-settings__card-title">
          <h4>
            {{ contents.applicationSettings }}
          </h4>
          <ViewHelpLink
            :documentationAnchor="contents.updateAppViewHelpDocAnchor"
          />
        </div>

        <UpdateApp v-if="application" :application="application" />
      </CardContainer>
      <SecretSynchronizationCard
        v-if="displaySecretSynchronizationCard"
        class="application-settings__card-container"
        :application="application"
      />
      <ApplicationSensitiveModifications
        class="application-settings__card-container"
        :application="application"
      />
    </div>
    <div class="application-settings__col">
      <AccessSettings />
      <DeclareIPAddressesCard class="application-settings__card-container" />
    </div>
  </div>
</template>

<script lang="ts">
import CardContainer from "@/commons/components/CardContainer.vue";
import ViewHelpLink from "@/commons/components/UserDocumentationLinks/ViewHelpLink.vue";
import UpdateApp from "@/dashboard/components/UpdateApp.vue";
import AccessSettings from "@/dashboard/views/AppDetails/AccessSettings.vue";
import ApplicationSensitiveModifications from "@/dashboard/views/AppDetails/ApplicationSensitiveModifications.vue";
import DeclareIPAddressesCard from "@/dashboard/views/AppDetails/DeclareIPAddressesCard.vue";
import SecretSynchronizationCard from "@/dashboard/views/AppDetails/SecretSynchronizationCard.vue";

import { Application } from "@/commons/domain/models/application";

import contents from "@/dashboard/contents/application";

export default {
  components: {
    ViewHelpLink,
    SecretSynchronizationCard,
    ApplicationSensitiveModifications,
    DeclareIPAddressesCard,
    AccessSettings,
    CardContainer,
    UpdateApp,
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    application(): Application {
      return this.$store.getters["currentApplication"];
    },
    userHasWriteAccessOnCurrentApplication() {
      return this.$store.getters["userHasWriteAccessOnCurrentApplication"];
    },
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    secretSynchronizationIsEnabled(): boolean {
      return this.$store.getters["config/secretSynchronizationIsEnabled"];
    },
    userIsInternal(): boolean {
      return this.$store.getters["userIsInternal"];
    },
    applicationHasApiKeyContracts(): boolean {
      return this.application?.metrics?.contracts?.apiKey > 0;
    },
    displaySecretSynchronizationCard(): boolean {
      return this.userIsInternal && this.applicationHasApiKeyContracts;
    },
  },
  mounted() {
    if (!this.userHasWriteAccessOnCurrentApplication) {
      this.$router.push({
        name: "application",
        params: { id: this.application.id },
      });
    }
  },
};
</script>

<style lang="scss">
.application-settings {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
}

.application-settings__col {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 500px;
  gap: 2rem;
}

.application-settings__card-container {
  margin: 0; // margin is managed with flex gaps
}

.application-settings__card-title {
  display: flex;
  justify-content: space-between;

  margin-bottom: 1.5rem;
}
</style>
