import momentTimezone from "moment-timezone";

import { labelizeTimezone } from "@/commons/libs/utils/stringUtils";

import contents from "@/commons/contents/search-timezone-autocomplete-wrapper";

export interface TimezoneItem {
  value?: string;
  label: string;
  hour?: string;
  isCategory?: boolean;
  disabled?: boolean;
}

export interface ExpirationDateDetails {
  isToday?: boolean;
  isTomorrow?: boolean;
  isYesterday?: boolean;
  diffInDays?: number;
}

const userLocale =
  navigator.languages != undefined
    ? navigator.languages[0]
    : navigator.language;

export const getTimezoneHour = (
  from: Date | string,
  timezone: string,
): string => {
  const formattedTimezone = extractTimezone(timezone);

  const formatter = Intl.DateTimeFormat(userLocale, {
    timeZone: formattedTimezone,
    timeStyle: "short",
  });

  return formatter
    .format(momentTimezone(from).tz(formattedTimezone).toDate())
    ?.toLowerCase();
};

export const getTimezoneDate = (
  from: Date | string,
  timezone: string,
): Date => {
  const formattedTimezone = extractTimezone(timezone);
  return momentTimezone(from).tz(formattedTimezone)?.toDate();
};

export const extractTimezone = (timezone: string): string => {
  return timezone?.split(" ")?.length > 1 ? timezone.split(" ")[0] : timezone;
};

export const getSuggestedUserTimezone = (): string | undefined => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getExpirationDateDetails = (
  date: Date | string,
): ExpirationDateDetails => {
  const userCurrentMoment = momentTimezone().tz(getSuggestedUserTimezone());
  const givenMoment = momentTimezone(date).tz(getSuggestedUserTimezone());

  const diffInDays = userCurrentMoment.diff(givenMoment, "days");

  return {
    isToday: givenMoment.isSame(userCurrentMoment, "day"),
    isTomorrow: givenMoment.isSame(
      userCurrentMoment.clone().add(1, "day"),
      "day",
    ),
    isYesterday: givenMoment.isSame(
      userCurrentMoment.clone().subtract(1, "day"),
      "day",
    ),
    diffInDays: Math.abs(diffInDays) + 1,
  };
};

export const mapToUTCTimezones = (
  givenTimezones: string[],
  availableTimezones: string[],
): string[] => {
  return givenTimezones
    .map((timezone: string) => {
      return availableTimezones.find((configTimezone: string) =>
        configTimezone.includes(timezone),
      );
    })
    .filter((zone) => zone != undefined);
};

export const mapTimezoneItems = (
  from: Date,
  timezones: string[],
  {
    isCommonZone,
    isSuggestedZone,
    isAvailableZone,
  }: {
    isCommonZone?: boolean;
    isSuggestedZone?: boolean;
    isAvailableZone?: boolean;
  },
): TimezoneItem[] => {
  return timezones.map((timezone) => {
    return {
      // prefixes are used to distinct the different categories of timezones
      value: `${isCommonZone ? "common-" : ""}${isAvailableZone ? "available-" : ""}${isSuggestedZone ? "suggested-" : ""}${timezone}`,
      label: labelizeTimezone(timezone),
      hour: getTimezoneHour(from, timezone),
    };
  });
};

export const addTimezoneCategories = (filteredList: TimezoneItem[]) => {
  addCategoryIfNeeded(
    contents.suggestedTimezoneCategory,
    filteredList,
    (item: TimezoneItem) => item.value?.includes("suggested-"),
  );

  addCategoryIfNeeded(
    contents.commonTimezonesCategory,
    filteredList,
    (item: TimezoneItem) => item.value?.includes("common-"),
  );

  addCategoryIfNeeded(
    contents.allTimeZones,
    filteredList,
    (item: TimezoneItem) => item.value?.includes("available-"),
  );
};

const addCategoryIfNeeded = (
  category: string,
  array: TimezoneItem[],
  checkFunc: Function,
) => {
  const firstItem = array.find((item) => checkFunc(item));

  if (firstItem != undefined) {
    const index = array.indexOf(firstItem);
    if (index >= 0) {
      pushTimezoneCategoryOnIndex(category, index, array);
    }
  }
};

const pushTimezoneCategoryOnIndex = (
  category: string,
  index: number,
  array: TimezoneItem[],
) => {
  array.splice(index, 0, getCategoryTimezoneItem(category));
};

export const getCategoryTimezoneItem = (title: string): TimezoneItem => {
  return {
    label: title,
    value: title,
    isCategory: true,
    disabled: true, // permits to use the category as a title
  };
};
