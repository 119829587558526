import { ActionContext } from "vuex";

import { getRequestSummaryRepository } from "@/commons/repositories/libs/get-request-summary-repository";
import { finishAction } from "@/commons/store/helpers/finish-action";
import {
  flagLoadingProcess,
  flagSavingProcess,
} from "@/commons/utils/flagProcess.utils";

import { RequestFetchParams } from "@/commons/domain/repositories/request-summary-repository";

import { State } from "./types";
import {
  ERequestFilteringVisibility,
  ERequestStatus,
  ERequestType,
  State as RootState,
} from "@/commons/store/types";

async function processRequest(
  { commit, rootGetters, getters }: ActionContext<State, RootState>,
  {
    decision,
    requestId,
    params,
  }: {
    decision: "approveRequest" | "denyRequest" | "cancelRequest";
    requestId: string;
    params: RequestFetchParams;
  },
) {
  if (
    !rootGetters["isSavingProperty"]("request-manager_requestSummariesPage")
  ) {
    await flagSavingProcess("requestSummaries", async () => {
      finishAction({
        commit,
        path: "requestManager",
        propertiesToReplace: {
          requestSummariesPage: await getRequestSummaryRepository()[decision](
            requestId,
            params,
          ),
        },
        afterSave() {
          const selectedRequest = getters.requestSummariesList.find(
            (s) => s.id === getters.currentRequest.id,
          );

          if (selectedRequest != undefined) {
            commit("patchState", {
              requestManager: {
                currentRequest: selectedRequest,
              },
            });
          }
        },
      });
    });

    if (params.requestId !== undefined) {
      await flagLoadingProcess("preselectedRequest", async () => {
        finishAction({
          commit,
          path: "requestManager",
          propertiesToReplace: {
            preselectedRequest: await getRequestSummaryRepository().findById(
              params.requestId,
            ),
          },
          afterSave() {
            if (
              getters.preselectedRequest &&
              getters.currentRequest.id === getters.preselectedRequest.id
            ) {
              commit("patchState", {
                requestManager: {
                  currentRequest: getters.preselectedRequest,
                },
              });
            }
          },
        });
      });
    }
  }
}

async function loadRequests(
  { commit }: ActionContext<State, RootState>,
  { params }: { requestId: string; params: RequestFetchParams },
) {
  await flagLoadingProcess("requestSummaries", async () => {
    finishAction({
      commit,
      path: "requestManager",
      propertiesToReplace: {
        requestSummariesPage:
          await getRequestSummaryRepository().findRequests(params),
      },
    });
  });
  if (params.requestId !== undefined) {
    await flagLoadingProcess("preselectedRequest", async () => {
      finishAction({
        commit,
        path: "requestManager",
        propertiesToReplace: {
          preselectedRequest: await getRequestSummaryRepository().findById(
            params.requestId,
          ),
        },
      });
    });
  }
}

async function findPendingRequests({
  commit,
}: ActionContext<State, RootState>) {
  flagLoadingProcess("pendingRequestSummaries", async () => {
    finishAction({
      commit,
      path: "requestManager",
      propertiesToReplace: {
        pendingRequestSummariesPage:
          await getRequestSummaryRepository().findRequests({
            filteringVisibility: ERequestFilteringVisibility.RECEIVED,
            statuses: ERequestStatus.APPROVAL_REQUIRED,
            idsOnly: true,
          }),
      },
    });
  });
}

async function fetchPendingAccessPolicyRequests(
  { commit }: ActionContext<State, RootState>,
  apiId: string,
) {
  flagLoadingProcess("pendingRequestSummaries", async () => {
    finishAction({
      commit,
      path: "requestManager",
      propertiesToReplace: {
        currentApiPendingAccessPolicyRequests:
          await getRequestSummaryRepository().findRequests({
            filteringVisibility: ERequestFilteringVisibility.SUBMITTED,
            statuses: ERequestStatus.APPROVAL_REQUIRED,
            apiId,
            types: ERequestType.UPDATE_ACCESS_POLICY,
          }),
      },
    });
  });
}

async function fetchPendingOtherGatewayRequests(
  { commit }: ActionContext<State, RootState>,
  apiId: string,
) {
  finishAction({
    commit,
    path: "requestManager",
    propertiesToReplace: {
      currentApiPendingOtherGatewayRequests:
        await getRequestSummaryRepository().findRequests({
          filteringVisibility: ERequestFilteringVisibility.SUBMITTED,
          statuses: ERequestStatus.APPROVAL_REQUIRED,
          apiId,
          types: ERequestType.OTHER_GATEWAYS,
        }),
    },
  });
}

async function fetchPendingTimeoutRequests(
  { commit }: ActionContext<State, RootState>,
  apiId: string,
) {
  finishAction({
    commit,
    path: "requestManager",
    propertiesToReplace: {
      currentApiPendingTimeoutRequests:
        await getRequestSummaryRepository().findRequests({
          filteringVisibility: ERequestFilteringVisibility.SUBMITTED,
          statuses: ERequestStatus.APPROVAL_REQUIRED,
          apiId,
          types: ERequestType.TIMEOUT,
        }),
    },
  });
}

async function loadRequestTypes({ commit }: ActionContext<State, RootState>) {
  finishAction({
    commit,
    path: "requestManager",
    propertiesToReplace: {
      requestTypes: await getRequestSummaryRepository().findRequestTypes(),
    },
  });
}

export default {
  loadRequests,
  findPendingRequests,
  fetchPendingAccessPolicyRequests,
  fetchPendingOtherGatewayRequests,
  fetchPendingTimeoutRequests,
  loadRequestTypes,
  processRequest,
};
